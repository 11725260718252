import React from 'react';
import qr_mars from './qr_mars.svg'; // Tell webpack this JS file uses this image
import qr_april from './qr_april.svg'; // Tell webpack this JS file uses this image
import qr_maj from './qr_maj.svg'; // Tell webpack this JS file uses this image
import qr_juni from './qr_juni.svg'; // Tell webpack this JS file uses this image
import qr_juli from './qr_juli.svg'; // Tell webpack this JS file uses this image
import qr_augusti from './qr_augusti.svg'; // Tell webpack this JS file uses this image
import qr_september from './qr_september.svg'; // Tell webpack this JS file uses this image
import qr_oktober from './qr_oktober.svg'; // Tell webpack this JS file uses this image
import qr_november from './qr_november.svg'; // Tell webpack this JS file uses this image
import qr_december from './qr_december.svg'; // Tell webpack this JS file uses this image*/

import './App.css';

class App extends React.Component {

  state={
    deptMonthNo : new Date().getMonth(),
  }

  render(){
    return (
      <div className="App">
           <header className="App-header">
           <img src={QR_KODER[nummerManad]} alt="qrkod" height="426" with="426" />
             <p>
             </p>
             <p>
             Beloppet f&ouml;r {dennaManad} m&aring;nad &auml;r: {SKULD[this.state.deptMonthNo]}  kr
             </p>
             <a
               className="App-link"
               href="https://www.nordea.se"
               target="_blank"
               rel="noopener noreferrer"
             >
               Jag vill betala via banken ist&auml;llet!
             </a>
             <p>
              <code> Den h&auml;r sidan laddades senast: {dagensDatum} </code>
             </p>

           </header>
         </div>
       );
  }
}


var dagensDatum = new Date().toLocaleString('sv-SV', { date: 'long' })
//console.log('dagensDatum = ' + dagensDatum);

var dennaManad = new Date().toLocaleString('sv-SV', { month: 'long' })
//console.log('dennaManad = ' + dennaManad);

var nummerManad = new Date().getMonth()
//.toLocaleString('sv-SV', { month: 'long' })
//console.log("nummerManad: " + nummerManad);


  const SKULD = [
  "1708",
  "1706",
  "1704",
  "1702",
  "1700",
  "1698",
  "1696",
  "1696",
  "1694",
  "1692",
  "1690",
  "1688",
  "1686",
  "1684",
  "1682",
  "1681",
  "1679",
  "1677",
  "1675",
  "1673",
  "1671",
  "1667"
]

const QR_KODER = [
  " ",
  " ",
  qr_mars,
  qr_april,
  qr_maj,
  qr_juni,
  qr_juli,
  qr_augusti,
  qr_september,
  qr_oktober,
  qr_november,
  qr_december];

export default App;
